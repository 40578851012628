
import { Component, Vue } from 'vue-property-decorator'
import { initWangEdit } from '@/utils/wangEdit'
import { ElForm } from 'element-ui/types/form'
import { Tag, Info } from '../../types/workGuide'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import { TypeInfo } from '../../types/common'

@Component({
  components: { UploadFile }
})
export default class Xxx extends Vue {
  private info: Info = {
    guideType: '0',
    workGuideTile: '',
    workGuideIntroduction: '',
    workGuideContent: '',
    resourceList: [],
    tagList: [],
    workContentType: '', // 作业内容分类
    plantType: '', // 植物类型
    plantName: '', // 植物名称
    isShow: 1
  }

  private rules = {
    guideType: [{ required: true, message: '请输入作业指导类型', trigger: ['change'] }],
    workGuideTile: [{ required: true, message: '请输入作业指导标题', trigger: ['blur', 'change'] }],
    workGuideIntroduction: [{ required: true, message: '请输入作业指导简介', trigger: ['blur', 'change'] }],
    workGuideContent: [{ required: true, message: '请输入作业指导内容', trigger: ['change'] }],
    resourceList: [{ required: true, message: '请上传修剪标准图片', trigger: ['change'] }]
  }

  private tagName = ''

  private tagList: Array<Tag> = []
  private GuideTypeList = []

  private submitShow = false
  private editor: any = null
  private addShow = false

  private workContentTypeList = [] // 作业内容分类
  private plantTypeList: TypeInfo[] = [] // 植物类别
  private plantNameList = [] // 植物类别
  private get title () {
    return this.$route.params.id ? '编辑' : '新增'
  }

  destroyed () {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  }

  created () {
    this.getTagList()
    this.init()
    this.getGuideTypeList()
    // 作业指导类型新增参数
    this.getWorkContentTypeList()
    this.getPlantTypeList()
    if (this.$route.params.id) {
      this.getDetail()
    }
  }

  // 获取作业指导类型列表
  getGuideTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'guideType' }).then(res => {
      this.GuideTypeList = res.guideType || []
    })
  }

  // 获取作业内容分类
  getWorkContentTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'workContentType' }).then(res => {
      this.workContentTypeList = res.workContentType || []
    })
  }

  // 植物类别
  getPlantTypeList () {
    this.$axios.get<{total: number; list: TypeInfo[]}>(this.$apis.common.thinktankTypeList, {
      dicType: 'plantType'
    }).then((res) => {
      this.plantTypeList = res.list || []
    })
  }

  // 获取植物
  getPlantType () {
    this.$axios.get(this.$apis.plant.plantList, {
      plantType: this.info.plantType
    }).then((res) => {
      this.plantNameList = res.list || []
    })
  }

  // 选择植物类别
  selectPlantType () {
    this.info.plantName = ''
    this.plantNameList = []
    this.getPlantType()
  }

  getDetail () {
    this.$axios.get(this.$apis.workGuide.workGuideDetail, {
      workGuideId: this.$route.params.id
    }).then((res) => {
      this.info = res
      // 植物类别不为空
      this.info.plantType && this.getPlantType()
      this.editor.txt.html(res.workGuideContent)
    })
  }

  // 作业指导类型改变
  changeGuideType () {
    this.info.resourceList = []
    this.info.workGuideIntroduction = ''
  }

  onSuccess (file: FileInfo) {
    console.log(file)
    this.info.resourceList.push(file)
    console.log(this.info.resourceList)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  getTagList () {
    this.$axios.get(this.$apis.common.thinktankTag).then(res => {
      this.tagList = res.list
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.editor = initWangEdit('#richText', { placeholder: '请输入作业指导内容' })
      this.editor.create()
    })
  }

  querySearch (queryString: string, cb: Function) {
    const tagList = this.tagList
    const results = queryString ? tagList.filter(this.createFilter(queryString)) : tagList
    this.addShow = results.length === 0
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createFilter (queryString: string) {
    return (tag: Tag) => {
      return (tag.tagName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
    }
  }

  // 标签选中
  handleSelect (v: Tag) {
    (this.info.tagList as Array<Tag>).push(v)
  }

  // 标签删除
  removeTag (index: number) {
    this.info.tagList.splice(index, 1)
  }

  // 自定义标签添加
  addTag () {
    const index = this.info.tagList.findIndex((item: Tag) => {
      return item.tagName === this.tagName
    })
    if (index === -1) {
      (this.info.tagList as Array<Tag>).push({
        tagId: null,
        tagName: this.tagName
      })
      this.tagName = ''
      this.addShow = false
    } else {
      this.$message({
        message: '标签重复',
        type: 'warning'
      })
    }
  }

  onSubmit () {
    // 获取富文本内容
    this.info.workGuideContent = this.editor.txt.html()
    if (this.info.guideType !== '0' && this.info.guideType !== '1') {
      delete this.info.workContentType
      delete this.info.plantType
      delete this.info.plantName
    }
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.id
          ? this.$apis.workGuide.workGuideUpdate
          : this.$apis.workGuide.workGuideAdd
        this.$axios.post(url, this.info).then(() => {
          this.submitShow = false
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$router.push({ name: 'workGuideList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
